import React from "react";
import { useInView } from "react-intersection-observer";
import OfficesMap from "./Map";

const Introduction = () => {
  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <section
      className={`flex justify-center min-h-[100vh] pt-[4rem] py-8 md:px-5 2xl:px-0 overflow-hidden`}
    >
      <div
        ref={textRef}
        className={`flex flex-col justify-center gap-6 max-w-7xl my-auto mx-auto h-full px-5 md:px-0 items-center ${
          textInView ? "visible animate-fade-in-bottom" : "invisible"
        }`}
      >
        <h1 className="text-center text-3xl">About Us</h1>
        <div className="flex flex-col">
          <div className="flex flex-col justify-around space-y-6">
            <div className="flex flex-col space-y-2">
              <h2 className="text-xl font-semibold">Our Team</h2>
              <p>
                We are a persistent and creative team of domain engineers,
                software engineers and applied mathematicians. Our extensive
                industry and academic backgrounds have helped build practical
                products that are delivering ROI for oil and gas companies
                around the world.
              </p>
            </div>
            <div className="flex flex-col space-y-2">
              <h2 className="text-xl font-semibold">Our Awards</h2>
              <p>
                Resermine is a leader in hybrid model deployment for reservoir
                engineering applications with global recognition –{" "}
                <a
                  href="https://jpt.spe.org/university-group-lists-most-promising-oil-gas-startups-year"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>Most Promising </b>
                </a>{" "}
                at Offshore Technology Conference (Houston, 2018) and{" "}
                <a
                  href="https://jpt.spe.org/spe-honors-2021-international-award-recipients-during-annual-meeting"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>SPE International Technical Award </b>
                </a>
                in Data Science and Engineering Analytics at SPE Annual
                Technical Conference and Exhibition (Dubai, 2021)
              </p>
            </div>
            <div className="flex flex-col space-y-2">
              <h2 className="text-xl font-semibold">Our Global Presence</h2>
              <p>
                Our Technology Development Center is in the USA, with Technology
                Delivery Centers in Muscat (Oman), Dubai (UAE), Kuala Lumpur
                (Malaysia) and Doha* (Qatar)
              </p>
            </div>
          </div>
        </div>
        <div className="flex w-full h-full justify-between flex-col lg:flex-row max-lg:space-y-4">
          <div className="w-[75%] max-lg:w-full h-full">
            <OfficesMap />
          </div>
          <div className="flex flex-col space-y-2 px-2 py-2 overflow-y-auto border-[1px] border-slate-300 rounded-xl lg:ml-2 max-h-[20rem] w-[25%] max-lg:w-full">
            <h1 className="text-lg">United States</h1>
            <p className="text-sm">
              251 Little Falls Drive, Wilmington, DE, 19808
            </p>
            <span className="border-b-[1px]" />
            <h1 className="text-lg">Oman</h1>
            <p className="text-sm">Al Hail South, Seeb, PO Box 1984, Muscat</p>
            <span className="border-b-[1px]" />
            <h1 className="text-lg">United Arab Emirates</h1>
            <p className="text-sm">
              Business Center 1, The Meydan Hotel, Dubai
            </p>
            <span className="border-b-[1px]" />
            <h1 className="text-lg">Malaysia</h1>
            <p className="text-sm">
              Megan Ave 1, 189 Jalan Tun Razak, Kuala Lumpur
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Introduction;
