export const allProductDetails = [
  {
    id: "wise",
    name: "HawkEye Wise",
    preview: `Browser-based reservoir simulation platform to build new reservoir simulation models, upload existing ones, and do post-processing on simulation results without recourse to keywords.`,
    description: `<ul style="list-style: circle;">
    <li>
    Convert existing desktop-based current simulation input/output files (any current simulation software) to browser-based files for pre and post-processing.
    </li>
    <li>
    Incorporate simulation file reviews and feedback directly on browser-based platform by sharing simulation input and output files directly with other users
    </li>
    <li>
    Edit plots and make data changes directly on the browser without recourse to simulation file keywords
    </li>
    <li>
    Configured to run on client’s internal cloud computers as well as external cloud computers.
    </li>
    </ul>
    `,
    image:
      "https://images.unsplash.com/photo-1496247749665-49cf5b1022e9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80",
  },
  {
    id: "surveillance",
    name: "HawkEye Surveillance",
    preview:
      "Hybrid model workflows to characterize reservoir and optimize injection operations – which wells and how much to water/gas/WAG/polymer/surfactants to inject.",
    description: `
    <ul style="list-style: circle;">
    <li >
    Hybrid model workflows that combine CRM, FMM and ML to eliminate shortcomings of individual models and determine how wells are connected to characterize the reservoir.
    </li>
    <li>
     Use connectivity analysis to identify which injectors are effective and which are not – to quantify the impact on oil production and prioritize well maintenance activities.
    </li>
    <li>
    Use multilayer connectivity analysis workflows to identify fluid and oil production from individual layers.
    </li>
    <li>
    Use advanced optimization to determine which wells and how much to inject based on current field constraints.
    </li>
    </ul>
    `,
    image:
      "https://images.unsplash.com/photo-1588011930968-eadac80e6a5a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80",
  },
  {
    id: "co2",
    name: "HawkEye CO₂ Management",
    preview: `Hybrid model workflows for CO₂ abatement by injection in mature conventional fields(continuous injection), and unconventional fields (CO₂ huff n puff) as well as quantify CO₂ emissions during sequestration.`,
    description: `
    <ul style="list-style: circle;">
    <li>
    Use hybrid model workflows – CRM, FMM, and ML models adapted for gas injection in mature conventional fields to identify effective/ineffective injectors and characterize reservoir.
    </li>
    <li>
    Determine optimum parameters for CO₂ huff and puff operations by coupling HawkEye’s advanced optimization engine with proxy models generated by a hybrid combination of neural network and reservoir simulations. 
    </li>
    <li>
    Integrate production data and standardize CO₂ emissions quantification and reporting across asset teams (ESG reporting, Life Cycle Analysis -  LCA adapted from Oil Production Greenhouse Emissions Estimator – OPGEE).
    </li>
    <li>
    Evaluate potential of specific assets for implementation of CO₂ injection and rank them based on CO₂ emission reduction. 
    </li>
    </ul>
    `,
    image:
      "https://images.unsplash.com/photo-1513828646384-e4d8ec30d2bb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
  },
  {
    id: "fdp",
    name: "HawkEye FDP",
    preview: `Hybrid model workflows to accelerate and optimize well locations and trajectories for greenfields and brownfields to maximize NPV.`,
    description: `
    <ul style="list-style: circle;">
    <li>
    Hybrid model workflows combining reduced physics (FMM) and reservoir simulations in a machine learning (ML) framework to generate proxy reservoir recovery maps using a single simulation input file.
    </li>
    <li>
    ntegrate reservoir recovery maps with HawkEye’s advanced optimization algorithms to obtain a simulation file containing optimum wells and their trajectories (verify the optimum by downloading the simulation file).
    </li>
    <li>
    Use static model directly as an input to obtain optimum well number and locations using FMM workflows (over 90% consistent with reservoir simulation workflow).
    </li>
    <li>
    Hybrid workflows that combine deep learning and model based inversion workflows to accelerate seismic inversion and obtain static model - further used to obtain optimum well locations.
    </li>
    </ul>
    `,
    image:
      "https://images.unsplash.com/photo-1518704618243-b719e5d5f2b8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
  },
  {
    id: "ahm",
    name: "HawkEye AHM",
    preview: `Hybrid model workflows to accelerate brownfield reservoir workflows by identifying key parameters that impact the history matching process using ML algorithms.`,
    description: `
    <ul style="list-style: circle;">
    <li>
    Hybrid model workflows that use reservoir simulations in a machine learning framework to determine which parameters impact the history match process.
    </li>
    <li>
    Incorporate uncertainties through multiple realizations and identify specific parameters impacting history matching. 
    </li>
    <li>
    Generate history-matched realizations using the identified parameters by HawkEye’s advanced optimization algorithms.
    </li>
    <li>
    Use a single simulation file to obtain a history matched simulation file that contains the optimum parameters.
    </li>
    </ul>
    `,
    image:
      "https://images.unsplash.com/photo-1605307660997-21db274a2c42?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2531&q=80",
  },
  {
    id: "well-log",
    name: "HawkEye Well Log AI",
    preview: `Hybrid model workflows to automate and accelerate welllog pre-processing and interpretation using advanced analytics and machine learning algorithms.`,
    description: `
    <ul style="list-style: circle;">
    <li>
    Accelerate data harmonization through direct upload on browser-based platform - add tags as well as assign families directly (pre-defined by enterprise). 
    </li>
    <li>
    Merge multiple overlapping depths by generating a single log and using predefined rules in settings (lower depth values used as default).
    </li>
    <li>
    Identify well logs needing depth correction and use backend cross correlation algorithms integrated with HawkEye’s optimization engine to automatically prompt depth correction.
    </li>
    <li>
    Populate missing well log sections as well as classify lithologies using backend ML algorithms that are trained directly using enterprise data.
    </li>
    </ul>
    `,
    image:
      "https://images.unsplash.com/photo-1539186607619-df476afe6ff1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80",
  },
  {
    id: "gen-ai",
    name: "HawkEye Gen AI",
    preview: `Generative AI models installed and trained locally using enterprise data and HawkEye engine for domain specific (subsurface) guard rails to avoid hallucinations.`,
    description: `
    <ul style="list-style: circle;">
    <li>
    Accelerate asset reviews using query-based retrieval of data, plots and reports using locally installed HawkEye Gen AI without recourse to any other proprietary software.
    </li>
    <li>
    Instantaneously create customizable dashboards that use data trained on HawkEye Gen AI (no codes required).
    </li>
    <li>
    Quickly convert across proprietary simulation input files – converted files need minimal work for interoperability between simulation software (over 80% consistent).
    </li>
    <li>
    Eliminate the need for expensive proprietary production software with production databases that are trained directly on the HawkEye Gen AI platform.
    </li>
    </ul>
    `,
    image:
      "https://images.unsplash.com/photo-1578356058390-f58c575337a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80",
  },
];
