import React from "react";
import Intro from "./Sections/Intro";
// import Features from "./Sections/Features/Features";
// import Partnerships from "./Sections/Partnerships/Partnerships";
// import Testimonials from "./Sections/Testimonials/Testimonials";

const Home = () => {
  return (
    <>
      <Intro />
      {/* <Features /> */}
      {/* <Partnerships /> */}
      {/* <Testimonials /> */}
    </>
  );
};

export default Home;
